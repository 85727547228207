<template>
  <div class="previewer" @keydown="handleKeyDown" tabindex="0" ref="container">
    <div class="previewer-content" @click="close">
      <div class="title">
        <div @click.stop>{{ filesList[current].name }}.{{ filesList[current].extension }}</div>
      </div>
      <div class="file">
        <img
          v-if="
            currentType === 'image' && documentInfo.ext !== 'TIF' && documentInfo.ext !== 'TIFF'
          "
          class="img"
          :src="filesList[current].url"
          @click.stop
          @dragstart.prevent
        />
        <div
          v-if="
            (currentType === 'document' && documentInfo.ext !== 'PDF') ||
            documentInfo.ext === 'TIF' ||
            documentInfo.ext === 'TIFF' ||
            documentInfo.ext === 'TXT' ||
            documentInfo.ext === 'CSV'
          "
          class="document"
          @click.stop
        >
          <div class="document-icon" :style="{ backgroundColor: documentInfo.bkg }">
            <svg width="36" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path
                  d="M0 14h12a2 2 0 002-2V0h20.0041C35.1061 0 36 .91 36 1.984v36.032A1.986 1.986 0 0134.014 40H1.986a1.9995 1.9995 0 01-1.839-1.2476A1.9998 1.9998 0 010 37.986V14zm0-4L10 .006V10H0z"
                  :fill="documentInfo.color"
                />
                <text
                  fill="#fff"
                  style="white-space: pre"
                  font-family="manrope"
                  font-size="10"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="4.0742" y="30.33">{{ documentInfo.ext }}</tspan>
                </text>
              </g>
              <defs>
                <clipPath id="clip0"><path fill="#fff" d="M0 0h36v40H0z" /></clipPath>
              </defs>
            </svg>
          </div>
          <div class="document-subtitle">No preview available</div>
        </div>
        <div v-if="currentType === 'document' && documentInfo.ext === 'PDF'" class="pdf-container">
          <iframe
            :src="`${filesList[current].url}#toolbar=0&navpanes=0&scrollbar=0`"
            width="850"
            heigth="100%"
            class="pdf"
          />
        </div>
        <div v-if="currentType === 'video'" class="video" @click.stop>
          <video-player :videoUrl="filesList[current].url" />
        </div>
      </div>
      <div class="footer">
        <action-bar
          :actions="actionBarOptions"
          isPreview
          :totalPreviewed="filesList.length"
          :currentPreviewed="current + 1"
          @click.native.stop
          @previewPrev="changePreview(false)"
          @previewNext="changePreview(true)"
        />
      </div>
    </div>
    <div class="previewer-bkg"></div>
    <div class="close-btn">
      <icon-button icon="ri-close-line" btnStyle="secondary" size="large" @submit="close" />
    </div>
  </div>
</template>

<script>
import ActionBar from '../actionBar';
import IconButton from '../iconButton';
import VideoPlayer from '../videoPlayer';

export default {
  components: {
    ActionBar,
    IconButton,
    VideoPlayer
  },
  props: {
    filesList: {
      type: Array,
      required: false,
      default: () => []
    },
    defaultCurrent: {
      type: Number,
      required: false,
      default: 0
    },
    isShare: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      current: this.defaultCurrent,
      actionBarOptions: [
        {
          icon: 'ri-download-line',
          tooltip: 'Download',
          method: () => {
            this.$emit('downloadItem', this.current);
          }
        },
        /* eslint-disable */
        !this.isShare
          ? {
              icon: 'ri-delete-bin-line',
              tooltip: 'Delete',
              method: () => {
                const idToDelete = this.filesList[this.current].id;
                if (this.current + 1 === this.filesList.length) {
                  this.current = 0;
                }
                this.$emit('deleteItem', idToDelete);
                if (this.filesList.length === 1) {
                  this.close();
                }
              }
            }
          : null
        /* eslint-enable */
      ].filter(el => el != null)
    };
  },
  created() {
    this.$nextTick(() => {
      this.$refs.container.focus();
    });
  },
  computed: {
    currentType() {
      return this.filesList[this.current].type;
    },
    documentInfo() {
      const doc = {};
      switch (this.filesList[this.current].extension) {
        case 'pdf':
        case 'ppt':
        case 'pptx':
          doc.ext = this.filesList[this.current].extension.toUpperCase();
          doc.color = '#FF1B0E';
          doc.bkg = 'rgba(255, 27, 14, 0.3)';
          break;
        case 'docx':
        case 'doc':
          doc.ext = this.filesList[this.current].extension.toUpperCase();
          doc.color = '#1A5FC1';
          doc.bkg = 'rgba(26, 95, 193, 0.3)';
          break;
        case 'xlsx':
        case 'xls':
          doc.ext = this.filesList[this.current].extension.toUpperCase();
          doc.color = '#0B8245';
          doc.bkg = 'rgba(11, 130, 69, 0.3)';
          break;
        case 'tif':
        case 'tiff':
          doc.ext = this.filesList[this.current].extension.toUpperCase();
          doc.color = '#FFC937';
          doc.bkg = '#FFF9EB';
          break;
        case 'csv':
        case 'txt':
          doc.ext = this.filesList[this.current].extension.toUpperCase();
          doc.color = '#777481';
          doc.bkg = 'rgba(119, 116, 129, 0.3)';
          break;
        default:
          doc.ext = '{EXT}';
          doc.color = '#777481';
          doc.bkg = 'rgba(119, 116, 129, 0.3)';
      }
      return doc;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changePreview(bool) {
      const { current, filesList } = this;
      if (bool) {
        this.current = current + 1 >= filesList.length ? 0 : current + 1;
      } else {
        this.current = current <= 0 ? filesList.length - 1 : current - 1;
      }
    },
    handleKeyDown(e) {
      e.preventDefault();
      if (e.keyCode === 39) {
        this.changePreview(true);
      }
      if (e.keyCode === 37) {
        this.changePreview(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.previewer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
  outline: none;
  :focus {
    outline: none;
  }
  &-content {
    z-index: 2;
    height: 100%;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      @include body-1;
      font-weight: 600;
      height: 88px;
      color: white;
    }
    .file {
      height: calc(100% - 88px - 88px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .img,
      .pdf {
        max-height: 100%;
        max-width: 88%;
        width: auto;
      }
      .pdf,
      .pdf-container {
        height: 100%;
        width: 850px;
        max-width: 88vw;
      }
      .video {
        max-width: 88vw;
        height: 100%;
      }
      .document {
        width: 210px;
        height: 189px;
        background-color: rgba(37, 36, 40, 0.7);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-icon {
          height: 88px;
          width: 88px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;
        }
        &-subtitle {
          @include body-1;
          color: $color_neutral_0;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 88px;
    }
  }

  &-bkg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background: $color_neutral_100;
    opacity: 0.5;
    top: 0;
  }
}
.close-btn {
  position: absolute;
  top: 24px;
  right: 40px;
  .rounded-button {
    background-color: white;
    border-radius: 50%;
  }
}

.ri-close-line {
  cursor: pointer;
}
</style>
