
<div class="previewer" @keydown="handleKeyDown" tabindex="0" ref="container">
  <div class="previewer-content" @click="close">
    <div class="title">
      <div @click.stop>{{ filesList[current].name }}.{{ filesList[current].extension }}</div>
    </div>
    <div class="file">
      <img
        v-if="
          currentType === 'image' && documentInfo.ext !== 'TIF' && documentInfo.ext !== 'TIFF'
        "
        class="img"
        :src="filesList[current].url"
        @click.stop
        @dragstart.prevent
      />
      <div
        v-if="
          (currentType === 'document' && documentInfo.ext !== 'PDF') ||
          documentInfo.ext === 'TIF' ||
          documentInfo.ext === 'TIFF' ||
          documentInfo.ext === 'TXT' ||
          documentInfo.ext === 'CSV'
        "
        class="document"
        @click.stop
      >
        <div class="document-icon" :style="{ backgroundColor: documentInfo.bkg }">
          <svg width="36" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                d="M0 14h12a2 2 0 002-2V0h20.0041C35.1061 0 36 .91 36 1.984v36.032A1.986 1.986 0 0134.014 40H1.986a1.9995 1.9995 0 01-1.839-1.2476A1.9998 1.9998 0 010 37.986V14zm0-4L10 .006V10H0z"
                :fill="documentInfo.color"
              />
              <text
                fill="#fff"
                style="white-space: pre"
                font-family="manrope"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="4.0742" y="30.33">{{ documentInfo.ext }}</tspan>
              </text>
            </g>
            <defs>
              <clipPath id="clip0"><path fill="#fff" d="M0 0h36v40H0z" /></clipPath>
            </defs>
          </svg>
        </div>
        <div class="document-subtitle">No preview available</div>
      </div>
      <div v-if="currentType === 'document' && documentInfo.ext === 'PDF'" class="pdf-container">
        <iframe
          :src="`${filesList[current].url}#toolbar=0&navpanes=0&scrollbar=0`"
          width="850"
          heigth="100%"
          class="pdf"
        />
      </div>
      <div v-if="currentType === 'video'" class="video" @click.stop>
        <video-player :videoUrl="filesList[current].url" />
      </div>
    </div>
    <div class="footer">
      <action-bar
        :actions="actionBarOptions"
        isPreview
        :totalPreviewed="filesList.length"
        :currentPreviewed="current + 1"
        @click.native.stop
        @previewPrev="changePreview(false)"
        @previewNext="changePreview(true)"
      />
    </div>
  </div>
  <div class="previewer-bkg"></div>
  <div class="close-btn">
    <icon-button icon="ri-close-line" btnStyle="secondary" size="large" @submit="close" />
  </div>
</div>
